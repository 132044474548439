.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-size: 50px;
}

.goBack {
  margin-top: 50px;
}
