.pageTitle>h4 {
  color: #43425d;
  font-size: 1.5rem;
}

.pageTitle>p {
  color: #626c8a;
  font-size: 1.125rem;
  font-weight: 400;
  padding-top: 0;
  margin-bottom: 1.5rem;
}

.descriptionCell {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.iconContainer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
}

.infoContainer .iconContainer {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 30px;
}

.info span {
  margin-right: 12px;
}

.info :global(.anticon) {
  margin-top: 8px;
  cursor: pointer;
  color: #1990ff;
}

.warningInterstitial {
  margin-top: -16px;
  color: rgb(230, 74, 74);
  font-style: italic;
}


/* FILTERS AREA */

.filtersContainer {
  background-color: #fafafa;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}

.filtersContainer>h3 {
  color: #4d4f5c;
  font-weight: 500;
  margin-bottom: 1rem;
}

.filtersContainer :global(.ant-row) {
  align-items: end;
}

.filterSpan {
  color: rgb(95, 95, 95);
  margin-bottom: 0.3rem;
  display: block;
}

.isDeletedCheckbox {
  display: flex;
  align-items: flex-end;
  padding-top: 10px;
  padding-bottom: 5px;
}

.selectFilter {
  height: 32px;
  width: 100%;
  margin-right: 1rem;
}

.filtersContainer :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector),
.filtersContainer :global(.ant-input),
.filtersContainer :global(.ant-picker) {
  border-radius: 5px;
}

.filtersContainer .updateButton {
  margin-top: 1rem;
}

.actionButtonsCheckbox {
  height: 60px;
  display: flex;
  align-items: center;
}

.actionButtonsCheckbox .approveSelected:global(.ant-btn-background-ghost.ant-btn-primary) {
  color: #3d9b4c;
  border-color: #3d9b4c;
  margin-right: 1rem;
}

/* ACRTION MODALS */

.detailsModal :global(.ant-modal),
.actionsModal :global(.ant-modal) {
  width: 600px !important;
}

.detailsModal :global(.ant-spin-spinning),
.actionsModal :global(.ant-spin-spinning) {
  display: flex;
  justify-content: center;
}

.deleteModal :global(.ant-modal-header),
.actionsModal :global(.ant-modal-header),
.detailsModal :global(.ant-modal-header) {
  border-bottom: none;
  padding: 20px 30px;
}

.deleteModal :global(.ant-modal-body),
.detailsModal :global(.ant-modal-body) {
  padding: 0px 30px;
}

.actionsModal :global(.ant-modal-body) {
  padding: 0 30px 20px 30px;
}

.deleteModal :global(.ant-modal-body) p {
  margin: 0;
  color: #4d4f5c;
}

.deleteModal :global(.ant-modal-footer),
.actionsModal :global(.ant-modal-footer),
.detailsModal :global(.ant-modal-footer) {
  border-top: none;
  padding: 20px 30px;
}

.detailsModal :global(.ant-modal-footer) button,
.actionsModal :global(.ant-modal-footer) button {
  width: 200px;
}

.actionModalButtons {
  margin: 0;
}

.actionModalButtons :global(.ant-form-item-control-input-content) {
  display: flex;
  justify-content: flex-end;
}

.actionModalButtons button {
  width: 200px;
}

.actionModalButtons button:first-child {
  margin-right: 8px;
}

.deleteModal :global(.ant-modal-footer) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.deleteModal :global(.ant-modal-footer) button {
  width: 48%;
}

.detailsModal .infoContainer {
  margin-bottom: 1rem;
  color: #4d4f5c;
}

.detailsModal .infoContainer p {
  margin: 0;
}

.detailsModal .infoContainer p:first-child {
  margin-bottom: 0.4rem;
  font-weight: 600;
}

.detailsModal .infoContainer .noData {
  font-style: italic;
  color: #bebebe;
}

.showClearOnAllUsers :global(.ant-select-clear) {
  opacity: 1;
}

.actionButtonsCheckbox {
  height: 60px;
  display: flex;
  align-items: center;
}

.actionButtonsCheckbox .deleteSelected:global(.ant-btn-background-ghost) {
  color: #d85c5c;
  border-color: #d85c5c;
  margin-right: 1rem;
}