
:global(tr.ant-table-expanded-row > td),
:global(tr.ant-table-expanded-row:hover > td) {
    background: #fff;
}

:global(.ant-table .ant-table-expanded-row-fixed) {
    padding: 30px 0px;
}

.rowsInfo {
    color: #4D4F5C;
    margin-bottom: 30px;
}

.nestedTableWrapper {
  margin: 0;
  border: 1px solid #D1D1D1;
}

.approveAll:global(.ant-btn) {
    padding: 0;
    width: 32px;
}

.approveAll span{
    transform: scale(1.2);
    color: #737373;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.approveAll:hover,
.approveAll:hover span {
    border-color: #58A54F;
    color: #58A54F;
}

.rejectAll:global(.ant-btn) {
    padding: 0;
    width: 32px;
}

.rejectAll span{
    transform: scale(1.4);
    color: #737373;
    position: relative;
    top: -1px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rejectAll:hover,
.rejectAll:hover span {
    border-color: #D85C5C;
    color: #D85C5C;
}
