:global(.ant-layout-sider-collapsed) .logo img {
  width: 60%;
}

:global(.ant-layout-sider-collapsed) .logo span {
  display: none;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.logo img {
  display: block;
  width: 55%;
  margin: 0 auto;
}

.logo span {
  font-weight: 600;
  position: relative;
  letter-spacing: 0.5px;
  font-size: 1rem;
  padding-top: 0.5rem;
  /* padding-left: 4px; */
}

.headerLeft {
  width: 100%;
  margin: 0 30px;
  display: flex;
  align-items: center;
}

.menuIcon {
  font-size: 1.3rem;
}

.moduleInitials {
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(233, 233, 233);
  background-color: #fafafa;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 1rem;
}

.dropdownContentFix {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.dropdownContentFix:first-child {
  padding-bottom: 0;
}

.dropdownContentFix>span {
  font-size: 0.8rem;
  color: rgb(169, 169, 169);
  margin-bottom: 0.8rem;
}

.dropdownContentFix>a {
  font-size: 0.8rem;
  width: 100%;
}

.btnDropdownLogout {
  color: rgb(101, 101, 101);
  font-size: 0.8rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.btnDropdownContent {
  color: rgb(101, 101, 101);
  font-size: 0.8rem;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  margin-bottom: 0;
  padding-bottom: 0;
  width: 100%;
}

.balanceField {
  color: #73b566;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.balanceField>img {
  height: 24px;
}

.balanceField>p {
  padding-left: 0.3rem;
  margin: 0;
}

.balanceField>img {
  padding-bottom: 0.2rem;
}

.headerDropdown :global(.ant-dropdown-menu) {
  max-width: 320px;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 14px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.headerDropdown :global(.ant-dropdown-menu-item) {
  padding: 0px 0px;
}

.headerDropdown :global(.ant-dropdown-menu-item):hover {
  background-color: transparent;
}

.menuDropdownItem {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem 1rem;
  color: rgb(90, 90, 90);
  border-top: 1px solid rgb(226, 226, 226);
  transition: all 0.2s;
}

.menuDropdownItem:hover {
  opacity: 0.8;
  color: #1890ff;
}

.itemDropdown {
  margin: 0;
  padding: 0.2rem 0.2rem;
  /* border: 1px solid red; */
  /* background-color: #4d8ded;
  color: #ffffff; */
  /* border-radius: 5px; */
}

/* CUSTOM SIDEBAR TRIGGER */
:global(.ant-layout-sider-zero-width-trigger) {
  background-color: #012d6d;
}

:global(.ant-layout.ant-layout-has-sider > .ant-layout) {
  overflow-x: hidden;
}

.dropdownManager {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 14px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  overflow: hidden;
}

.accManagerContact {
  background-color: #ffffff;
  color: #74767f;
  margin: 0;
  padding: 0.7rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.accManagerContact:hover {
  background-color: #f7f7f7;
  color: #1990ff;
}

.headerRightUser {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: opacity 0.2s;
}

.headerRightUser:hover {
  opacity: 0.8;
}

.headerRightUser>span {
  white-space: nowrap;
}

.headerRightAccManager {
  margin-right: 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: opacity 0.2s;
}

.headerRightAccManager:hover {
  opacity: 0.8;
}

.headerRightAccManager>span {
  white-space: nowrap;
}

.accManagerDesc {
  background: linear-gradient(0deg,
      rgba(247, 247, 247, 1) 0%,
      rgba(255, 255, 255, 1) 80%);
  color: #4d4f5c;
  padding: 0.7rem 1rem;
  font-size: 0.8rem;
  margin: 0;
}

.centerCoinisBtn {
  background-color: rgb(22, 72, 146);
  border: 0px;
  border-radius: 0.7rem;
  width: 100px;
  height: 45px;
}

.centerCoinisBtn:hover {
  background-color: rgba(1, 45, 109, 0.8);
}

.centerCoinisBtn:focus {
  background-color: rgba(1, 45, 109, 0.8);
}

.centerCoinisBtn>img {
  height: 30px;
}

.customMenuItem {
  display: block;
  width: 100%;
  height: 100%;
}

.hasError {
  font-size: 10px;
}

:global(.ant-layout-sider-children) {
  overflow: auto;
}

:global(.ant-menu.ant-menu-dark) {
  background: transparent;
}

:global(.ant-menu.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected) {
  background-color: #2e71b6;
  border-bottom: 1.5px solid #e0a64e;
}

:global(.ant-menu-dark .ant-menu-inline.ant-menu-sub) {
  background: transparent;
  box-shadow: none;
  /* background: #012d6d;
  box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.45); */
}

:global(.ant-layout-sider-trigger) {
  background: #2964a9;
}

:global(.ant-layout-sider-zero-width-trigger) {
  top: 10px;
  z-index: 9999;
}

:global(.ant-layout-sider-children) {
  position: relative;
}

.headerAccManager {
  padding-right: 0.8rem;
  max-height: 63.99px;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  text-align: right;
}

.headerAccManager>span {
  line-height: 0.9rem;
}

.headerAccManager>span:nth-child(1) {
  color: #848484;
}

.headerAccManager>span:nth-child(2) {
  font-weight: bold;
  color: rgb(87, 87, 87);
}

:global(.ant-layout-header) {
  line-height: 1.4rem;
  display: flex;
  flex-direction: row-reverse;
  white-space: nowrap;
}

.sidebarContent :global(.ant-menu-item a::before),
.sidebarContent :global(.ant-menu-submenu .ant-menu-submenu-title) {
  z-index: 10;
}

.sidebarContent :global(.ant-layout-sider-children) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebarContent :global(.ant-menu) {
  z-index: 1;
  /* position: absolute; */
}

.sidebarModuleDisp {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 350px;
  white-space: nowrap;
  color: #e0a64e;
  font-size: 1.1rem;
  padding-bottom: 1rem;
  font-weight: 600;
  bottom: 0;
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0;
  background-position-x: center;
  background-position-y: 80%;
  background-size: 203px;
  background-repeat: no-repeat;
}

.sidebarModuleDisp>span {
  transition: color 0.2s;
}

.sidebarModuleDisp>span:first-child:hover {
  color: #e0a54e72;
}

.sidebarModuleDisp>span:first-child {
  padding-bottom: 1rem;
  word-wrap: break-word;
  cursor: pointer;
}

.centerAction {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerRight {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: flex-end;
}

.domainUpdateMessage {
  background: rgb(255, 187, 85);
  padding: 0.3rem;
  display: flex;
  align-content: center;
  justify-content: center;
  color: #3a5b91;
  font-weight: 700;
}

.headerRight svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.downArrow svg {
  color: rgb(180, 180, 180);
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

.headerUsername {
  max-height: 63.99px;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  text-align: right;
}

.headerUsername>span {
  line-height: 0.9rem;
}

.headerUsername>span:nth-child(1) {
  color: #848484;
}

.headerUsername>span:nth-child(2) {
  color: rgb(169, 169, 169);
}

.headerHighligh {
  color: #1890ff;
  font-weight: bold;
}

.emailHighlight {
  font-weight: 100;
}

.accManagerAvatar {
  width: 38px;
  height: 38px;
  object-fit: cover;
  position: relative;
  border-radius: 50%;
}

.profileAvatar {
  width: 38px;
  height: 38px;
  object-fit: cover;
  position: relative;
  bottom: 2px;
  border-radius: 50%;
}

.profileDropdownAvatar {
  width: 68px;
  height: 68px;
  object-fit: cover;
  position: relative;
  border-radius: 50%;
  margin-bottom: 0.6rem;
}

/* SIDEBAR MENU RESTYLE */

:global(.ant-menu-dark.ant-menu-inline .ant-menu-item) {
  padding-right: 13px;
}

:global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected) {
  background-color: transparent;
}

:global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected) .menuItemRestyle {
  background-color: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.14);
}

:global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected) .menuItemRestyle>a {
  color: #faad14 !important;
}

:global(.ant-menu-inline-collapsed > .ant-menu-item) {
  padding: 0;
  display: flex;
  justify-content: center;
}

:global(.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title) {
  padding-left: 22px;
}

:global(.ant-layout-sider-collapsed) .menuItemRestyle {
  border-radius: 6px;
  padding-left: 0px;
  display: flex;
  justify-content: center;
  width: 40px;
  max-height: 40px;
}

:global(.ant-layout-sider-collapsed) .menuItemRestyle span:nth-of-type(2) {
  display: none !important;
}

/* 
:global(.ant-layout-sider-collapsed) .sidebarModuleDisp {
  background: none;
} */

:global(.ant-layout-sider-collapsed) .mobileAppCointainer {
  display: none;
}

:global(.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed) {
  width: 100%;
}

.menuItemRestyle {
  border-radius: 6px;
  padding-left: 10px;
  max-height: 40px;
}

.menuItemRestyle>a {
  transition: 0s;
  color: #fff;
}

.menuItemRestyle>a>span {
  transition: all 0.2s;
  color: inherit !important;
}

:global(.ant-menu.ant-menu-dark .ant-menu-sub) {
  color: rgba(255, 255, 255, 0.65);
  background: #154486;
}

:global(.ant-menu-item.ant-menu-item-only-child.ant-menu-item-active) .menuItemRestyle>a {
  color: #faad14 !important;
}

:global(.ant-menu-submenu-title) {
  color: #fff;
}

.submenuTitleRestyle {
  padding: 10px;
}

:global(.ant-menu-inline-collapsed) .submenuTitleRestyle {
  padding: 0;
}

:global(.ant-layout-sider) {
  background-image: url(https://publisher.coinis.com/images/moduleBackground.png);
  background-size: cover;
}

@media (max-width: 600px) {
  :global(.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow) {
    display: none;
  }
}

@media screen and (max-height: 800px) {
  .sidebarContent :global(.ant-layout-sider-children) {
    height: 100vh;
  }

  .sidebarModuleDisp {
    position: relative;
    height: auto;
    background-image: none !important;
  }

  .sidebarContent {
    height: 100%;
  }

  .sidebarContent :global(.ant-menu) {
    z-index: 1;
    position: relative;
  }
}

h1 {
  font-size: 2em;
}

.modalButton {
  width: 100%;
  overflow: hidden;
}