.pageTitle {
    font-size: 1.5rem;
    color: #43425d;
    margin-bottom: 1.2rem;
}
.mainTableContainer :global(.ant-table-thead > tr > th) {
    padding: 13px;
}
.mainTableContainer :global(.ant-table-tbody > tr > td) {
    padding: 13px;
}
.mainTableContainer :global(.ant-table-row:nth-child(odd)) {
    background-color: #f5f6fa;
}
.mainTableContainer :global(.ant-table tfoot > tr > td) {
    border-top: 1px solid rgb(90, 94, 146);
}

.countryBox {
    display: flex;
    align-items: center;
  }
  .countryBox > img {
    margin-right: 0.5rem;
  }