.drawerTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.drawerTitle img {
    margin-right: .5rem;
}

.drawerTitle h3 {
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    color: #4D4F5C;
}

.drawerCmpDetails {
    margin-top: .7rem;
    margin-left: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.drawerCmpDetails p {
    color: #4D4F5C;
    font-size: 18px;
    font-weight: 400;
    margin: 0 .5rem;
}

.creativesApprovalContainer {
    height: 100%;
}

.creativesApprovalContainer :global(.ant-spin-nested-loading),
.creativesApprovalContainer :global(.ant-spin-container),
.creativesApprovalContainer :global(.ant-row) {
    height: 100%;
}

.creativesApprovalContainer :global(.ant-col) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.creativeDetailsContainer {
    border: 1px solid #CCCCCC;
    height: 49%;
}

.creativeDetailsContainerDisabled {
    border: 1px solid #ebebeb;
    height: 49%;
}

.imagesContainer {
    height: 90%;
}

.creativeDetailsContainer .headerTitle {
    background-color: #FBFBFD;
    border-bottom: 1px solid #CCCCCC;
    height: 60px;
    padding: 0 1.5rem;
    color: #4d4f5c;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.creativeDetailsContainerDisabled .headerTitle {
    background-color: #FBFBFD;
    border-bottom: 1px solid #ebebeb;
    height: 60px;
    padding: 0 1.5rem;
    color: #4d4f5c;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.creativeDetailsContainer .headerTitle>p {
    margin: 0;
    padding: 0;
    color: #4D4F5C;
    font-weight: 600;
    font-size: 16px;
}

.creativeDetailsContainerDisabled .headerTitle>p {
    margin: 0;
    padding: 0;
    color: #868686;
    font-weight: 600;
    font-size: 16px;
}

.headerTitle :global(.ant-btn) {
    padding: 0;
    width: 24px;
    height: 24px;
    margin-left: .7rem;
    color: #707070;
}

.headerTitle :global(.ant-btn:hover),
.headerTitle :global(.ant-btn:active),
.headerTitle :global(.ant-btn:focus) {
    border: 1px solid #d9d9d9;
}

.headerTitle .approveGroup span {
    transform: scale(1.2);
}

.headerTitle .rejectGroup span {
    transform: scale(1.4);
    position: relative;
    top: -1px;
}

.itemsValue {
    max-width: 70%;
    line-break: anywhere;
    display: flex;
    align-items: center;
}

.iconContainer {
    width: 45px;
    height: 45px;
    margin-right: 1rem;
    border-radius: 5px;
    position: relative;
    display: flex;
}

.previewLink {
    color: #1890FF;
    cursor: pointer;
}

.iconContainer>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.imageContainer {
    width: 100px;
    height: 100px;
    margin-right: 1rem;
    border-radius: 5px;
    position: relative;
    display: flex;
}

.imageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.itemsList {
    list-style: none;
    margin: 0;
    padding: 0;
    height: calc(100% - 60px);
    overflow-y: scroll;
}

.itemsList li {
    min-height: 60px;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #CCCCCC;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.itemsListImages li {
    height: auto;
    padding: .8rem 1.5rem;
}

.itemsActions {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.itemsActions label,
.headerTitle label {
    padding-left: .7rem;
}

.itemsActions :global(.ant-btn-icon-only) {
    width: 24px;
    height: 24px;
    position: relative;
    top: -1px;
}

.itemsActions :global(.ant-checkbox-wrapper) {
    margin: 0;
}

.itemsActions :global(.ant-checkbox-inner) {
    width: 24px;
    height: 24px;
}

.itemsActions :global(.anticon) {
    font-size: 14px;
}

.itemsActions :global(.ant-checkbox-checked::after) {
    border: 1px solid transparent;
}

.itemsActions :global(.ant-checkbox:hover .ant-checkbox-inner) {
    border-color: #d9d9d9;
}

.approveCheckBox :global(.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner) {
    border-color: #58A54F;
}

.approveCheckBox :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: #58A54F;
    border-color: #58A54F;
}

.approveCheckBox :global(.ant-checkbox-inner::after) {
    left: 50%;
    top: 52%;
}

.approveCheckBox :global(.ant-checkbox-checked .ant-checkbox-inner::after) {
    border: none;
    transform: scale(1.1) translate(-50%, -50%);
    content: '\2713';
    color: #fff;
}

.rejectCheckBox :global(.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner) {
    border-color: #D85C5C;
}

.rejectCheckBox :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: #D85C5C;
    border-color: #D85C5C;
}

.rejectCheckBox :global(.ant-checkbox-inner::after) {
    left: 55%;
    top: 55%;
}

.rejectCheckBox :global(.ant-checkbox-checked .ant-checkbox-inner::after) {
    border: none;
    transform: scale(1.3) translate(-50%, -50%);
    content: '\00d7';
    color: #fff;
}

/* STATUS AREA */

.statusColumn,
.status,
.campaignType {
    display: flex;
    align-items: center;
    justify-content: center;
}

.status {
    text-transform: capitalize;
    min-width: 90px;
    max-width: 90px;
    position: relative;
    margin: 3px 0;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 3px;
    padding: 0.1rem 0.5rem;
    border: 1px solid transparent;
    background-color: #d1d1d11a;
    border: 1px solid #d1d1d1;
    color: #d1d1d1;
}

.statusactive {
    background-color: #02875b1a;
    border-color: #02875b;
    color: #02875b;
}

.statuspaused,
.statussuspended {
    background-color: #d1d1d11a;
    border-color: #d1d1d1;
    color: #d1d1d1;
}

.statusrejected {
    background-color: #d85c5c1a;
    border-color: #d85c5c;
    color: #d85c5c;
}

.statuspending {
    background-color: #faad141a;
    border-color: #faad14;
    color: #faad14;
}

.statusready {
    background-color: #02875b1a;
    border: 1px solid #02875b;
    color: #02875b;
}

.statusimplementing {
    background-color: #1990ff1a;
    border: 1px solid #1990ff;
    color: #1990ff;
}

.statusdisabled {
    background-color: #d85c5c1a;
    border-color: #d85c5c;
    color: #d85c5c;
}

.statusrequested {
    background-color: #781edf1a;
    border-color: #781edf;
    color: #781edfc9;
}