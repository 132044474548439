.titleProfile {
    color: #43425d;
    font-weight: 500;
    font-size: 1.4rem;
}
.profileContainer :global(.ant-tabs-tab-btn) {
    font-weight: 500;
}
.personalDetailsContainer {
    width: 70%;
    max-width: 680px;
}
.tabsSection :global(.ant-tabs-ink-bar) {
    height: 4px;
    background-color: #458edf;
}
.tabsSection :global(.ant-tabs-nav) {
    font-weight: normal;
    color: #606060;
    font-size: 1rem;
  }
  .tabsSection :global(.ant-tabs-nav .ant-tabs-tab-active) {
    font-weight: normal;
    color: #6c6c6c;
  }
  .tabsSection :global(.ant-tabs-nav .ant-tabs-tab) {
    /* margin-right: 30%; */
    padding: 0.6rem 1rem;
  }

.personalDetailsTop,
.personalDetailsBottom {
    display: flex;
}
.personalDetailsBottom {
    justify-content: flex-end;
}
.userInfo {
    padding-top: 2rem;
    padding-bottom: 1rem;
    display: flex;
}
.userInfoLeft {
    margin-left: 2rem;
}
.userInfo > div > p {
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(78, 78,78);
    width: 100%;
}
.userInfo :global(.ant-input) {
    padding: 0.4rem 1.2rem;
    border: 1px solid #f5f5f5;
    font-weight: 500;
    width: 300px;
    color: #6d6d6d;
}
.imagePreviewModal {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 35px 50px 0px 10px;
    border-radius: 50%;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
    overflow: hidden;
}
.imagePreviewModal img {
    width: 130px;
    height: 130px;
    object-fit: cover;
}
@media (max-width: 1100px) {
    .personalDetailsTop,
    .personalDetailsBottom {
        flex-direction: column;
        align-items: center;
    }
    :global(.ant-input) {
        width: 100%;
    }
    .personalDetailsContainer {
        width: 100%;
    }
}