.pageTitle>h4 {
  color: #43425d;
  font-size: 1.5rem;
}

.pageTitle>p {
  color: #626c8a;
  font-size: 1.125rem;
  font-weight: 400;
  padding-top: 0;
  margin-bottom: 1.5rem;
}

/* CAMPAIGNS FILTERS AREA */

.filtersContainer {
  background-color: #fafafa;
  padding: 1rem 1.5rem;
}

.filtersContainer>h3 {
  color: #4D4F5C;
  font-weight: 500;
  margin-bottom: 1rem;
}

.filtersContainer :global(.ant-row) {
  align-items: end;
}

.filterSpan {
  color: rgb(95, 95, 95);
  margin-bottom: .3rem;
  display: block;
}

.selectFilter {
  height: 32px;
  width: 100%;
  margin-right: 1rem;
}

.filtersContainer :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector),
.filtersContainer :global(.ant-input) {
  border-radius: 5px;
}

.filtersContainer .updateButton {
  margin-top: 1rem;
}

.filtersContainer :global(.ant-radio-button-wrapper) {
  color: #2f6fb6;
  font-weight: 300;
  font-size: 0.9rem;
  background-color: transparent;
  border: none;
}

.filtersContainer :global(.ant-radio-button-wrapper-checked) {
  font-weight: 600;
}

.filtersContainer :global(.ant-radio-button-wrapper:not(:first-child)::before) {
  display: none;
}

.filtersContainer :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover) {
  color: #2f6fb6;
  border-color: #2f6fb6;
}

.filtersContainer :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within) {
  box-shadow: 0 0 0 3px #1890ff14;
}

.dateRadioSelect {
  margin-bottom: 0.5rem;
}

/* ACTION GROUP UNDER FILTERS */

.extActionGroup {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 1rem;
}

.extActionGroup .extActionRightGroup :global(.ant-btn) {
  color: #464646;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
}

.extActionGroup .extActionRightGroup :global(.ant-btn):hover {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff;
}

.extActionGroup .extActionRightGroup :global(.ant-btn):not(:last-child) {
  margin-right: 0.5rem;
}

/* STATUS AREA */

.statusColumn,
.status,
.campaignType {
  display: flex;
  align-items: center;
  justify-content: center;
}

.status {
  text-transform: capitalize;
  min-width: 90px;
  max-width: 90px;
  position: relative;
  margin: 4px 0;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  background-color: #d1d1d11a;
  border: 1px solid #d1d1d1;
  color: #d1d1d1;
}

.campaignType {
  font-weight: bold;
  font-size: 1rem;
}

.statuscompleted {
  background-color: #02875b1a;
  border-color: #02875b;
  color: #02875b;
}

.statuscancelled {
  background-color: #d1d1d11a;
  border-color: #d1d1d1;
  color: #d1d1d1;
}

.statussuspended {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}

.statusfailed {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}

.statusprocessing {
  background-color: #faad141a;
  border-color: #faad14;
  color: #faad14;
}

.statusready {
  background-color: #02875b1a;
  border: 1px solid #02875b;
  color: #02875b;
}

.statusinit {
  background-color: #1990ff1a;
  border: 1px solid #1990ff;
  color: #1990ff;
}

.statusdisabled {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}

.statusrequested {
  background-color: #781edf1a;
  border-color: #781edf;
  color: #781edfc9;
}

/* ACTION BUTTONS */

.approve:global(.ant-btn),
.suspend:global(.ant-btn) {
  color: #737373;
}

.approve:global(.ant-btn:hover) {
  border-color: #0085F9;
  color: #0085F9;
}

.suspend:global(.ant-btn:hover) {
  border-color: #D85C5C;
  /*#0085F9*/
  color: #D85C5C;
  /*#0085F9*/
}

.actionButtonsCheckbox {
  height: 60px;
  display: flex;
  align-items: center;
}

.actionButtonsCheckbox .approveSelected:global(.ant-btn-background-ghost.ant-btn-primary) {
  color: #3D9B4C;
  border-color: #3D9B4C;
  margin-right: 1rem;
}

.actionButtonsCheckbox .suspendSelected:global(.ant-btn-background-ghost.ant-btn-primary) {
  color: #FFA430;
  border-color: #FFA430;
}

.dataTable :global(table tr th.ant-table-selection-column),
.dataTable :global(table tr td.ant-table-selection-column) {
  padding-right: .5rem;
  padding-left: 1.5rem;
  text-align: center;
}

.greenText {
  font-weight: 600;
  color: #58A54F;
}

.approve:global(.ant-btn:hover) img.activateCampaignIcon {
  content: url("../../../public/images/activate_hover.png");
}

.suspend:global(.ant-btn:hover) img.suspendCampaignIcon {
  content: url("../../../public/images/suspend_hover_1.png");
}