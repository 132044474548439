.filtersContainer {
    background-color: #fafafa;
    padding: 0.5rem 1rem 1rem 1rem;
  }

  .filtersContainer
  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-radius: 5px;
}

.filtersContainer :global(.ant-picker) {
  border-radius: 5px;
}

.filtersContainer :global(.Select-control) {
  border-radius: 2px;
}
.filtersContainer :global(.Select-arrow) {
  border-color: #d9d9d9 transparent transparent;
}

.filtersContainer :global(.ant-radio-button-wrapper) {
  color: #2f6fb6;
  font-weight: 300;
  font-size: 0.9rem;
  background-color: transparent;
  border: none;
}

.filtersContainer :global(.ant-radio-button-wrapper-checked) {
  font-weight: 600;
}

.filtersContainer :global(.ant-radio-button-wrapper:not(:first-child)::before) {
  display: none;
}

.filtersContainer :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover) {
  color: #2f6fb6;
  border-color: #2f6fb6;
}

.filtersContainer :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within) {
  box-shadow: 0 0 0 3px #1890ff14;
}

.columnsBtn {
  color: #464646;
  border: 1px solid rgb(196, 196, 196);
  margin-left: 0;
  border-radius: 3px;
  margin-right: 1rem;
}

.columnsBtn:last-child {
  margin-right: 0px;
}

.dateRadioSelect {
  margin-bottom: 0.5rem;
}

.topActionBtns {
  padding: 0.8rem 0;
}

  .bottomActions {
    margin-top: 2rem;
    display: flex;
  }

.advancedFilters {
  color: rgb(95, 95, 95);
}

.bottomActions {
  margin-top: 2rem;
  display: flex;
}
.bottomActions .rightActionBtns > * {
  margin-left: 0;
}

.rightActionBtns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottomActions :global(.ant-radio-button.ant-radio-button-checked) {
  background-color: #3d8af8;
  border-color: #3d8af8;
}

@media(max-width: 1000px) {
.bottomActions{
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;
}
.rightActionBtns {
  width: 100%;
  flex-direction: column;
  align-items: stretch;
}

.bottomActions :global(.ant-radio-group-solid) {
  display: none;
}

.bottomActions .rightActionBtns > * {
  margin-left: 0rem;
  margin-top: 0.5rem;
  width: 100%;
}
}