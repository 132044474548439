.drawerContainer :global(.ant-drawer-header) {
  padding: 1.5rem 1.5rem 1.5rem 2rem;
}

.drawerContainer :global(.ant-drawer-extra) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawerContainer :global(.ant-drawer-body) {
  padding: 1.5rem 2rem;
}

.drawerTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.drawerTitle img {
  margin-right: .5rem;
}

.drawerTitle h3 {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  color: #4D4F5C;
}

.campaignDetailsContainer {
  min-height: 70vh;
  position: relative;
}

.campaignDetailsContainer :global(.ant-spin-spinning) {
  position: static;
  display: block;
  opacity: 1;
  margin: auto;
}

.campaignDetailsContainer h3 {
  color: #4D4F5C;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: .75rem;
}

.campaignDetailsContainer h3:not(:first-child) {
  margin-top: 1.5rem;
}

.campaignDetailsContainer :global(.ant-row) {
  margin-bottom: .75rem;
}

.campaignDetailsContainer :global(.ant-row) div:first-child {
  color: #555555;
  font-weight: 500;
}

.campaignDetailsContainer :global(.ant-row) div:nth-child(2) {
  color: #4D4F5C;
}

.individualDetailsContainer {
  margin-top: 1.5rem;
}

.individualDetailsContainer :global(.ant-row) {
  margin-left: -0.5rem;
}

/* STATUS AREA */

.statusColumn,
.status,
.campaignType {
  display: flex;
  align-items: center;
  justify-content: center;
}

.status {
  text-transform: capitalize;
  min-width: 90px;
  max-width: 90px;
  position: relative;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid transparent;
  background-color: #d1d1d11a;
  border: 1px solid #d1d1d1;
  color: #d1d1d1;
}

.targetingInfoType {
  text-transform: capitalize;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: inline-block;
  color: #555555;
  font-weight: 500;
}

.targetingInfoValue {
  border-radius: 2px;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  display: inline-block;
  text-transform: capitalize;
  border: 1px solid #989898;
  background-color: #9898981A;
  color: #989898;
  font-weight: 400;
}

.statusActiveSpan {
  border: 1px solid #989898;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  display: inline-block;
  text-transform: capitalize;
  background-color: #9898981A;
  color: #989898;
  font-weight: 400;
}

.statusInactiveSpan {
  display: none;
}

.campaignDetailsContainer :global(.ant-table-wrapper) {
  width: 100%;
}

.generalInfoLabel {
  width: 200px;
  display: inline-block;
  color: #878787;
}

.generalInfoValue {
  font-weight: 500;
  text-transform: capitalize;
}

.targetingInfoHeader {
  width: 100%;
  margin-bottom: 10px;
}

.targetingInfoQuantity {
  width: 100%;
  margin-bottom: 5px;
}

.targetingInfoValueList {
  text-transform: capitalize;
  background-color: #ffffff;
  color: #1890ff;
  font-weight: 500;
  padding: 2px 0px;
  border-radius: 5px;
  margin-right: 10px;
}

.trackingInput {
  width: 80%;
  margin-bottom: 10px;
}

.trackingParagraph {
  margin: 0.5rem 0;
  color: #555555;
}

.trackingUrl :global(.ant-input) {
  color: #555555;
}

.trackingParamSpan {
  color: #BA4747;
}

.statusactive {
  display: block;
  text-transform: capitalize;
  min-width: 90px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  background-color: #02875b1a;
  border-color: #02875b;
  color: #02875b;
  text-align: center;
}

.statusinactive {
  display: block;
  text-transform: capitalize;
  min-width: 90px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
  text-align: center;
}

.trackingRow :global(.ant-input-suffix) {
  margin-left: 11px;
}

.trackingRow :global(.ant-btn) {
  border-radius: 5px;
  padding-top: 0;
  padding-bottom: 0;
  height: 30px;
  margin: 3px 0;
}