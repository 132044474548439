.pageTitle > h4 {
    color: #43425d;
    font-size: 1.5rem;
  }
  .pageTitle > p {
    color: #626c8a;
    font-size: 1.125rem;
    font-weight: 400;
    padding-top: 0;
    margin-bottom: 1.5rem;
  }
  
  /* CAMPAIGNS FILTERS AREA */
  
  .filtersContainer {
    background-color: #fafafa;
    padding: 1rem 1.5rem;
  }
  
  .filtersContainer > h3 {
    color: #4D4F5C;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .filtersContainer :global(.ant-row) {
    /* align-items: end; */
  }
  
  .filterSpan {
    color: rgb(95, 95, 95);
    margin-bottom: .3rem;
    display: block;
  }
  
  .selectFilter {
    /* height: 32px; */
    width: 100%;
    margin-right: 1rem;
  }
  
  .filtersContainer :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector), 
  .filtersContainer :global(.ant-input) {
    border-radius: 5px;
  }
  
  .filtersContainer .updateButton {
    margin-top: 26px;/*1rem*/
    height: 32px;
  }
  
  /* STATUS AREA */
  
  /* .statusColumn, */
  /* .campaignType,  */
  .status {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .status {
    color: #fff;
    text-transform: capitalize;
    min-width: 90px;
    max-width: 90px;
    position: relative;
    margin: 4px 0;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 3px;
    padding: 0.1rem 0.5rem;
    border: 1px solid transparent;
  }
  
  .campaignType {
    font-weight: bold;
    font-size: 1rem;
  }
  
  .statusactive {
    background-color: #02875b1a;
    border-color: #02875b;
    color: #02875b;
  }

  .statusapproved {
    background-color: #02875b1a;
    border-color: #02875b;
    color: #02875b;
  }
  
  .statuspaused, .statussuspended {
    background-color: #d1d1d11a;
    border-color: #d1d1d1;
    color: #d1d1d1;
  }
  
  .statusrejected {
    background-color: #d85c5c1a;
    border-color: #d85c5c;
    color: #d85c5c;
  }
  
  .statuspending {
    background-color: #faad141a;
    border-color: #faad14;
    color: #faad14;
  }
  
  .statusready {
    background-color: #02875b1a;
    border: 1px solid #02875b;
    color: #02875b;
  }
  
  .statusimplementing {
    background-color: #1990ff1a;
    border: 1px solid #1990ff;
    color: #1990ff;
  }
  
  .statusdisabled {
    background-color: #d85c5c1a;
    border-color: #d85c5c;
    color: #d85c5c;
  }
  
  .statusrequested {
    background-color: #781edf1a;
    border-color: #781edf;
    color: #781edfc9;
  }

  .statusColumn
  .campaignType {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5b49a7;
  }

  .campaignType {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    font-weight: bold;
    font-size: 1rem;
  }
  .campaignType span {
    position: relative;
    left: -15px;
    font-size: 0.85rem;
  }

  .campaignTypePushCpa {
    color: #32b66b;
  }
  .campaignTypePushCpc {
    color: #d95c5c;
  }
  .campaignTypeNativeCpc {
    color: #3067ce;
  }
  .campaignTypeSmartlinkCpa {
    color: #781edf;
  }
  
  /* ACTION BUTTONS */
  
  .approve:global(.ant-btn),
  .suspend:global(.ant-btn) {
    color: #737373;
  }
  
  .approve:global(.ant-btn:hover) {
    border-color: #0085F9;
    color: #0085F9;
  }
  
  .suspend:global(.ant-btn:hover) {
    border-color: #0085F9;/*#D85C5C*/
    color: #0085F9;/*#D85C5C*/
  }
  
  .actionButtonsCheckbox {
    height: 60px;
    display: flex;
    align-items: center;
  }
  
  .actionButtonsCheckbox .approveSelected:global(.ant-btn-background-ghost.ant-btn-primary){
    color: #3D9B4C;
    border-color: #3D9B4C;
    margin-right: 1rem;
  }
  
  .actionButtonsCheckbox .suspendSelected:global(.ant-btn-background-ghost.ant-btn-primary){
    color: #FFA430;
    border-color: #FFA430;
  }

  .dataTable {
    margin-top: 1.5rem;
  }
  
  .dataTable :global(table tr th.ant-table-selection-column),
  .dataTable :global(table tr td.ant-table-selection-column) {
    padding-right: .5rem;
    padding-left: 1.5rem;
    text-align: center;
  }
  
  .greenText {
    font-weight: 600;
    color: #58A54F;
  }
  
  .approve:global(.ant-btn:hover) img.activateCampaignIcon {
    content:url("../../../public/images/activate_hover.png");
  }
  
  .suspend:global(.ant-btn:hover) img.suspendCampaignIcon {
    content:url("../../../public/images/suspend_hover.png");
  }
  