.pageTitle>h4 {
    color: #43425d;
    font-size: 1.5rem;
}

.pageTitle>p {
    color: #626c8a;
    font-size: 1.125rem;
    font-weight: 400;
    padding-top: 0;
    margin-bottom: 1.5rem;
}

.formContainer {
    width: 600px;
}

.descriptionContainer {
    position: relative;
}

.descriptionLink {
    position: absolute;
    top: 34px;
    right: -220px;
}

.descriptionLinkModal :global(.ant-form-item) {
    margin-bottom: 12px;
}

.info span {
    margin-right: 12px;
}

.info :global(.anticon) {
    margin-top: 8px;
    cursor: pointer;
    color: #1990ff;
}

.formContainer :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
    padding-left: 0;
    color: #1990FF;
}

.iconsListWrapper {
    margin-bottom: 8px;
}

.iconsListWrapper :global(.ant-select-selection-item) {
    display: flex;
    align-items: center;
}

.iconContainer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
}

.notificationType label {
    width: 160px;
    text-align: center;
}

.warningInterstitial {
    margin-top: -16px;
    color: rgb(230, 74, 74);
    font-style: italic;
}

.notificationType :global(.ant-radio-button-wrapper:first-child),
.notificationType :global(.ant-radio-button-wrapper:last-child) {
    border-radius: 2px;
}

.notificationType label:not(:last-child) {
    margin-right: 16px;
}

.showClearOnAllUsers :global(.ant-select-clear) {
    opacity: 1;
}

@media screen and (max-width: 900px) {
    .formContainer {
        width: 100%;
    }
}

@media screen and (max-width: 430px) {
    .notificationType :global(.ant-radio-button-wrapper:first-child) {
        margin-bottom: 8px;
    }
}