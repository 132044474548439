.pageTitle > h4 {
  color: #43425d;
  font-size: 1.5rem;
}
.pageTitle > p {
  color: #626c8a;
  font-size: 1.125rem;
  font-weight: 400;
  padding-top: 0;
  margin-bottom: 1.5rem;
}

/* CAMPAIGNS FILTERS AREA */

.filtersContainer {
  background-color: #fafafa;
  padding: 1rem 1.5rem;
}

.filtersContainer > h3 {
  color: #4D4F5C;
  font-weight: 500;
  margin-bottom: 1rem;
}

.filtersContainer :global(.ant-row) {
  align-items: end;
}

.filterSpan {
  color: rgb(95, 95, 95);
  margin-bottom: .3rem;
  display: block;
}

.selectFilter {
  height: 32px;
  width: 100%;
  margin-right: 1rem;
}

.filtersContainer :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector), 
.filtersContainer :global(.ant-input) {
  border-radius: 5px;
}

.filtersContainer .updateButton {
  margin-top: 1rem;
}

.actionButtonsCheckbox {
  height: 60px;
  display: flex;
  align-items: center;
}

.actionButtonsCheckbox .approveSelected:global(.ant-btn-background-ghost.ant-btn-primary){
  color: #3D9B4C;
  border-color: #3D9B4C;
  margin-right: 1rem;
}

/* STATUS AREA */

.status {
  text-transform: capitalize;
  min-width: 90px;
  max-width: 90px;
  position: relative;
  margin: auto;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  background-color: #d1d1d11a;
  border: 1px solid #d1d1d1;
  color: #d1d1d1;
}

.campaignType {
  font-weight: bold;
  font-size: 1rem;
}

.statusactive {
  background-color: #02875b1a;
  border-color: #02875b;
  color: #02875b;
}

.statusinactive {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}

.statuspaused, .statussuspended {
  background-color: #d1d1d11a;
  border-color: #d1d1d1;
  color: #d1d1d1;
}

.statusrejected {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}

.statuspending {
  background-color: #faad141a;
  border-color: #faad14;
  color: #faad14;
}

.statusready {
  background-color: #02875b1a;
  border: 1px solid #02875b;
  color: #02875b;
}

.statusimplementing {
  background-color: #1990ff1a;
  border: 1px solid #1990ff;
  color: #1990ff;
}

.statusdisabled {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}

.statusrequested {
  background-color: #781edf1a;
  border-color: #781edf;
  color: #781edfc9;
}

/* SUBTABLE CONTENT STYLE */

.urlWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.url {
  max-width: 75%;
  line-break: anywhere;
  display: flex;
  align-items: center;
}

.urlAction {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.urlAction label, .headerTitle label {
  padding-left: .7rem;
}

.urlAction :global(.ant-btn-icon-only) {
  width: 24px;
  height: 24px;
  position: relative;
  top: -1px;
}

.urlAction :global(.ant-checkbox-wrapper){
  margin: 0;
}

.urlAction :global(.ant-checkbox-inner) {
  width: 24px;
  height: 24px;
}

.urlAction :global(.anticon){
  font-size: 14px;
}

.urlAction :global(.ant-checkbox-checked::after){
  border: 1px solid transparent;
}

.urlAction :global(.ant-checkbox:hover .ant-checkbox-inner){
  border-color: #d9d9d9; 
}