.campaignsTable>h4 {
  color: #43425d;
  font-size: 1.5rem;
}

.campaignsTable>p {
  color: #626c8a;
  font-size: 1.125rem;
  font-weight: 400;
  padding-top: 0;
  margin-bottom: 1.5rem;
}

.billingHeadInfo {
  font-size: 0.9rem;
  display: flex;
  margin-bottom: 1rem;
}

.billingHeadInfo>div {
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
  color: rgb(89, 89, 89);
}

.billingHeadInfo>div>span:first-child {
  font-weight: 600;
  margin-bottom: 0.2rem;
  color: rgb(61, 61, 61);
}

.headingActions {
  display: flex;
  margin-bottom: 30px;
}

.newCampaignBtn {
  background-color: #3d8af8;
}

.headingActions>div {
  margin-right: 20px;
}

.columnGroupContainer {
  width: 500px;
}

.columnGroupContainer :global(.ant-checkbox-inner) {
  width: 20px;
  height: 20px;
}

.searchInput {
  width: 250px;
}

.campaignsTable table thead th {
  background-color: #fff;
  color: #707070;
  font-size: 0.9rem;
}

.campaignsTable table tbody {
  font-size: 0.85rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808080 !important;
}

.campaignsTable table tfoot {
  color: #707070;
  font-size: 0.85rem;
  font-weight: 500;
}

.campaignsTable :global(.ant-table-row) {
  background-color: #fff;
}


.campaignsTable table tfoot :global(.ant-table-row) {
  background: #fafafa;
}

.campaignsTable :global(.ant-table-footer) {
  background-color: #fff;
}

.statusColumn,
.status,
.campaignNameWrapper,
.campaignType {
  display: flex;
  align-items: center;
  justify-content: center;
}

.status {
  background-color: #d1d1d11a;
  border: 1px solid #d1d1d1;
  color: #d1d1d1;
}

.campaignType {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  font-weight: bold;
  font-size: 1rem;
}

.campaignType span {
  position: relative;
  left: -15px;
  font-size: 0.85rem;
}

.campaignNameWrapper {
  justify-content: flex-start;
}

/* .campaignsTable .campaignNameColumn {
  padding-left: 50px;
} */

.status {
  color: #fff;
  text-transform: capitalize;
  min-width: 80px;
  position: relative;
  border-radius: 5px;
  margin: 10px 0;
}

.statusActive {
  background: #c2d791 0% 0% no-repeat padding-box;
  border: 1px solid #c2d791;
}

.statusPaused {
  background: #d95c5c 0% 0% no-repeat padding-box;
  border: 1px solid #d95c5c;
}

.statussuspended {
  background: #d95c5c 0% 0% no-repeat padding-box;
  border: 1px solid #d95c5c;
}

.statusPending {
  background-color: #f9ae3b;
}

.statusready {
  background-color: #347ab6;
}

.statusimplementing {
  padding: 0 2px;
  background-color: #5dc0df;
}

.statusdisabled {
  background-color: #495058;
}

.campaignTypePushCpa {
  color: #32b66b;
}

.campaignTypePushCpc {
  color: #d95c5c;
}

.campaignTypeNativeCpc {
  color: #3067ce;
}

.campaignTypeSmartlinkCpa {
  color: #781edf;
}

.nameInitials {
  width: 2.3em;
  height: 2.3em;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(85, 85, 85);
  padding: 5px;
  margin-right: 5px;
  text-align: center;
}

.campaignsTable :global(.ant-table-tbody > tr > td),
.campaignsTable :global(.ant-table-summary > tr > td) {
  padding: 10px 12px;
}

.channelName,
.companyName,
.dateCreated {
  text-align: left;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* .channelName {
  width: 200px;
} */
.campaignId {
  font-size: 0.8rem;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions>div {
  cursor: pointer;
  margin-right: 10px;
}

.actions div:last-child {
  margin-right: 0;
}

.actions :global(.ant-popover-inner-content) {
  padding: 5px 8px !important;
}

/* CAMPAIGNS TITLE AND FILTERS AREA */
.filtersContainer {
  background-color: #fafafa;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.filtersContainer :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-radius: 5px;
}

.filtersContainer :global(.ant-picker) {
  border-radius: 5px;
  margin-top: .5rem;
}

.filtersContainer :global(.Select-control) {
  border-radius: 2px;
}

.filtersContainer :global(.Select-arrow) {
  border-color: #d9d9d9 transparent transparent;
}

.filtersContainer :global(.ant-radio-button-wrapper) {
  color: #2f6fb6;
  font-weight: 300;
  font-size: 0.9rem;
  background-color: transparent;
  border: none;
}

.filtersContainer :global(.ant-radio-button-wrapper-checked) {
  font-weight: 600;
}

.filtersContainer :global(.ant-radio-button-wrapper:not(:first-child)::before) {
  display: none;
}

.filtersContainer :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover) {
  color: #2f6fb6;
  border-color: #2f6fb6;
}

.filtersContainer :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within) {
  box-shadow: 0 0 0 3px #1890ff14;
}

.plusIconCamp {
  font-size: 25px;
  font-weight: bold;
}

.dateRadioSelect {
  font-size: 0.9rem;
}

.campaignsTable :global(.ant-radio-group-large .ant-radio-button-wrapper) {
  border: none;
}

.campaignsTable :global(.ant-radio-group-large .ant-radio-button-wrapper::before) {
  display: none;
}


.hideShowLinkBtn {
  color: #3d8af8;
  padding: 0.5rem 0 0 0;
  font-size: 0.8rem;
}

.spanMarginBottom {
  color: rgb(95, 95, 95);
}

.advancedFilters {
  color: rgb(95, 95, 95);
}

.advancedFilters :global(.ant-row) {
  display: flex;
  align-items: flex-end;
}

.rangeDatePicker {
  height: 32px;
  margin-right: 1rem;
}

.inputTextSearch {
  max-width: 400px;
  width: 50%;
  min-width: 150px;
  margin-right: 1rem;
}

.selectStatuses,
.selectFormats {
  height: 32px;
  width: 100%;
  margin-right: 1rem;
}

.applyFiltersBtn {
  margin-top: 0.7rem;
  /* width: 100%; */
}

/* .campaignsTable .selectStatuses :global(.ant-select-selector),
.campaignsTable .selectFormats :global(.ant-select-selector) {
  height: 100%;
} */

.extActionGroup {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 1rem;
}

.exportToCSV {
  border: none;
  box-shadow: none;
  color: #3d8af8;
}

.columnsBtn,
.reloadBtn {
  color: #a3a6b4;
  margin-left: 1rem;
  border: 1px solid #bcbec6;
  border-radius: 0;
}

.countryDesc {
  display: flex;
  align-items: center;
}

/* LINK MODAL */
.actionGroupLink {
  display: flex;
  justify-content: flex-end;
}

.copyLinkBtn {
  margin-top: 0.5rem;
  background-color: #f9ae3b;
  border-color: #f9ae3b;
  color: #ffffff;
  /* float: right; */
}

@media (max-width: 1000px) {
  .inputTextSearch,
  .selectStatuses,
  .rangeDatePicker,
  .selectFormats {
    width: 100%;
    max-width: none;
    margin: 0;
  }

  .applyFiltersBtn {
    max-width: 400px;
  }


  .extActionGroup {
    flex-direction: column;
  }

  .titleContent {
    display: flex;
    flex-direction: row;
  }

  .columnsBtn,
  .reloadBtn {
    margin-bottom: 0.4rem;
  }
}

.actionCampaignIcons {
  font-size: 17px;
  position: relative;
  top: 3px;
}